import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Generar from "./generar";
import TiendaDashBoard from "./tiendadashboard";

import Blog from "./blog";
import BlogPost from "./BlogPost";
import Consulta from "./consulta";
import Cursos from "./cursos";
import Descarga from "./descarga";
import TiendaHistoricos from "./historico_envios";
import Mensajeria from './mensajeria';
import Registros from "./registros";
import TiendaVentas from './TiendaVentas';
import Usuario from "./usuario";

//import Configuracion from "./configuracion";
import Salir from "./salir";
//import TiendaDashBoard from "./TiendaDashBoard";
//import { useNavigate } from "react-router-dom";//para salir 


const Tienda = () => { 
  const [selectedItem, setSelectedItem] = useState("tiendadashboard");
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  //const navigate = useNavigate();//para sarir
  //const [desde, setDesde] = useState("");
  //const [hasta, setHasta] = useState("");
  const [userId, setUserId] = useState("");
  
  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    console.log("userId:" + userId)
    const fecha = new Date();
    const anio = fecha.getFullYear(); // obtiene el año (por ejemplo, 2023)
    const mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // obtiene el mes con dos dígitos (por ejemplo, "04")
    const dia = ("0" + fecha.getDate()).slice(-2); // obtiene el día con dos dígitos (por ejemplo, "26")
    const fechaHasta = `${anio}-${mes}-${dia}`; // combina los componentes de fecha en una cadena con el formato "aaaa/mm/dd"
    console.log("fechafotmateada HASTA: " + fechaHasta); // "2023/04/26"
  

    const diasAtras = 7; // cuántos días atrás quieres obtener la fecha
    const fechaMenor = new Date(
      fecha.getTime() - diasAtras * 24 * 60 * 60 * 1000
    ); // resta los días a la fecha actual

    const anioD = fechaMenor.getFullYear(); // obtiene el año (por ejemplo, 2023)
    const mesD = ("0" + (fechaMenor.getMonth() + 1)).slice(-2); // obtiene el mes con dos dígitos (por ejemplo, "04")
    const diaD = ("0" + fechaMenor.getDate()).slice(-2); // obtiene el día con dos dígitos (por ejemplo, "23")

    const fechaDesde = `${anioD}-${mesD}-${diaD}`; // combina los componentes de fecha en una cadena con el formato "aaaa/mm/dd"
    console.log("fechafotmateada desde: " + fechaDesde); // "2023/04/23"


    localStorage.setItem("fechaDesde", fechaDesde); //datauser
    localStorage.setItem("fechaHasta", fechaHasta); //data mac user

    console.log("FECHA DESDE " + fechaDesde);
    console.log("fecha hasta" + fechaHasta);
  },[userId]);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "salir") {
      window.location.href = "https://www.shipmaster.com.ar";
    } else {
      setSelectedItem(item);
    }
  };

  const menuItems = [
    { id: 'tiendadashboard', label: 'DashBoard', icon: 'fas fa-chart-bar', color: '#b87f05' },
    { id: 'generar', label: 'Nuevo Envio', icon: 'fas fa-chart-line', color: '#0c5da1' },
    { id: 'viajando', label: 'Entregados', icon: 'fas fa-database', color: '#6b6b6b' },
    { id: 'colecta', label: 'Colecta', icon: 'fas fa-database', color: '#6b6b6b' },
    { id: 'vendidos', label: 'Ventas', icon: 'fas fa-chart-line', color: '#0c5da1' },
    { id: 'historico', label: 'Historico de envios', icon: 'fas fa-leaf', color: 'green' },
    { id: 'mensajeria', label: 'Mensajeria', icon: 'fas fa-send', color: 'blue' },
    // { id: "consulta", label: "Consultas",icon:"fas fa-question-circle", color:"#43099a" },
    // { id: "descarga", label: "Descargas" ,icon:"fas fa-download", color:"#0c5da1"},
    // { id: "usuario", label: "Usuario" ,icon:"fas fa-user", color:"#839192"},
    // { id: "blog", label: "Blog" ,icon:"fas fa-Blog", color:"#CF9951"},
    //{ id: "cursos", label: "Cursos" ,icon:"fas fa-graduation-cap", color:"darkblue"},
    // { id: "configuracion", label: "Configuracion" },
    { id: 'salir', label: 'Salir', icon: 'fas fa-sign-out-alt', color: 'red' },
  ];

  return (
    <div className='dashboard'>
      <div className={`sidebar ${isSidebarVisible ? 'expanded' : ''}`}>
        <ul>
          {menuItems.map((menuItem) => (
            <li
              key={menuItem.id}
              onClick={() => handleItemClick(menuItem.id)}
              className={selectedItem === menuItem.id ? 'active' : ''}
            >
              <i class={menuItem.icon} style={{ color: menuItem.color }}></i> {menuItem.label}
            </li>
          ))}
        </ul>
      </div>
      <div className='contentDash'>
        <div className='toggle-container'>
          <div className={`toggle-button ${isSidebarVisible ? 'expanded' : ''}`} onClick={toggleSidebar}>
            <span className='arrow'></span>
          </div>
        </div>
        {/* Componentes del dashboard */}
        <div className={`content ${isSidebarVisible ? 'expanded' : ''}`}>
          {/* Renderizar el componente correspondiente según la selección del menú */}
          {selectedItem === 'tiendadashboard' && <TiendaDashBoard />}
          {selectedItem === 'generar' && <Generar />}
          {selectedItem === 'viajando' && <Registros />}
          {selectedItem === 'vendidos' && <TiendaVentas />}
          {selectedItem === 'historico' && <TiendaHistoricos />}
          {selectedItem === 'mensajeria' && <Mensajeria />}
          {selectedItem === 'consulta' && <Consulta />}
          {selectedItem === 'descarga' && <Descarga />}
          {selectedItem === 'usuario' && <Usuario />}
          {selectedItem === 'blog' && <Blog />}
          {selectedItem === '/:id' && <BlogPost />}
          {selectedItem === 'cursos' && <Cursos />}
          {/* {selectedItem === "configuracion" && <Configuracion />} */}
          {selectedItem === 'salir' && <Salir />}
        </div>
      </div>
    </div>
  );
};

export default Tienda;
