import { GoogleMap, Marker, Polyline, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader'; // Importa el spinner
import './camino.css';


const Encamino = () => {
  const [userId, setUserId] = useState('');
  const [choferes, setChoferes] = useState([]);
  const [choferesUnicos, setChoferesUnicos] = useState([]);
  const [hoveredChofer, setHoveredChofer] = useState(null);
  const [recorrido, setRecorrido] = useState([]);
  const [showRecorrido, setShowRecorrido] = useState(null);
  const [loading, setLoading] = useState(true); // Nuevo estado para carga

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBpj-VpabLaoiJPo9wdZAMGOFHGJYkXbVs',
  });

  useEffect(() => {
    const fetchChoferes = async (userId) => {
      setLoading(true); // Muestra el spinner
      try {
        const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/ubicacion_gps.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });
        const data = await response.json();
        if (data.status === 'success') {
          const choferesConImagenes = data.data.map((chofer) => ({
            ...chofer,
            imagen: `https://www.shipmaster.com.ar/img/choferes/${chofer.imagen}`,
          }));
          setChoferes(choferesConImagenes);
          const choferesMap = {};
          choferesConImagenes.forEach((chofer) => {
            if (!choferesMap[chofer.id_chofer]) {
              choferesMap[chofer.id_chofer] = chofer;
            }
          });
          setChoferesUnicos(Object.values(choferesMap));
        } else {
          console.error('No se encontraron datos de choferes');
        }
      } catch (error) {
        console.error('Error fetching choferes:', error);
      } finally {
        setLoading(false); // Oculta el spinner
      }
    };

    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
      fetchChoferes(storedUserId);
    }
  }, []);

  const handleShowRecorrido = (chofer) => {
    console.log('Recorrido:', chofer.recorrido); // Verifica los datos del recorrido
    setRecorrido(chofer.recorrido || []);
    setShowRecorrido(chofer.id_chofer);
  };

  const handleHideRecorrido = () => {
    setShowRecorrido(null);
  };

  const handleMouseEnter = (chofer) => {
    setHoveredChofer(chofer);
  };

  const handleMouseLeave = () => {
    setHoveredChofer(null);
  };

  if (loadError) {
    return <div>Error al cargar Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Cargando Google Maps...</div>;
  }

  const formattedRecorrido = recorrido.map((point) => ({
    lat: parseFloat(point.latitud),
    lng: parseFloat(point.longitud),
  }));

  return (
    <div className='bodycamino'>
      <h3 className='h3camino'>Choferes en Ruta</h3>
      <div className='chofer-containercamino'>
        {choferesUnicos.map((chofer, index) => (
          <div
            key={index}
            className='chofer-cardcamino'
            onMouseEnter={() => handleMouseEnter(chofer)}
            onMouseLeave={handleMouseLeave}
          >
            <img src={chofer.imagen} alt={`Imagen de ${chofer.id_chofer}`} />
            <span>{chofer.nombre}</span>
            <button className='buttoncamino' onClick={() => handleShowRecorrido(chofer)}>
              Ver Recorrido
            </button>
            {hoveredChofer && hoveredChofer.id_chofer === chofer.id_chofer && (
              <div className='tooltipcamino'>
                <p>
                  <strong>Nombre:</strong> {hoveredChofer.nombre}
                </p>
                <p>
                  <strong>Teléfono:</strong> {hoveredChofer.telefono}
                </p>
                <p>
                  <strong>Vehículo:</strong> {hoveredChofer.vehiculo}
                </p>
                <p>
                  <strong>Patente:</strong> {hoveredChofer.patente}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className='map-containercamino'>
        {loading ? (
          <div className='loading-containercamino'>
            <ClipLoader size={50} color={'#123abc'} loading={loading} />
          </div>
        ) : choferes.length > 0 ? (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            zoom={13}
            center={{ lat: -34.6037, lng: -58.3816 }}
          >
            {choferes.map((chofer, index) => (
              <Marker
                key={index}
                position={{ lat: parseFloat(chofer.latitud), lng: parseFloat(chofer.longitud) }}
                icon={{
                  url: chofer.imagen,
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(20, 20), 
                }}
              />
            ))}
            {formattedRecorrido.length > 0 && showRecorrido && (
              <Polyline
                path={formattedRecorrido}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            )}
          </GoogleMap>
        ) : (
          <p className='no-datacamino'>No hay datos de choferes disponibles.</p>
        )}
      </div>
    </div>
  );
};

export default Encamino;
