import { useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import Camino from './camino';
import './logisticadash.css';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: -34.603722, // Coordenadas de ejemplo, puedes cambiarlas a las que necesites
  lng: -58.381592,
};

const LIBRARIES = ['places', 'geometry', 'marker'];

const LogisticaDash = () => {
  const [id_logis, setId_logis] = useState(null);
  const [data, setData] = useState({
    ventasRealizadas: 0,
    enviosColectados: 0,
    pedidosEnLogistica: 0,
    pedidosEnRuta: 0,
    pedidosReprogramados: 0,
    pedidosCancelados: 0,
    choferes: [],
  });
  const [showRecorrido, setShowRecorrido] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBpj-VpabLaoiJPo9wdZAMGOFHGJYkXbVs', // Coloca tu API Key aquí
    libraries: LIBRARIES,
  });

  const mapRef = useRef(null);

  useEffect(() => {
    const storedIdLogis = localStorage.getItem('id_logis');
    setId_logis(storedIdLogis);
  }, []);

  useEffect(() => {
    if (!id_logis) return;

    const fetchData = async () => {
      try {
        const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/dashboardlogistica.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id_logis }),
        });
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id_logis]);

  const {
    ventasRealizadas,
    enviosColectados,
    pedidosEnLogistica,
    pedidosEnRuta,
    pedidosReprogramados,
    pedidosCancelados,
    choferes,
  } = data;

  const handleShowRecorrido = (chofer) => {
    setShowRecorrido(chofer.recorrido || []);
  };

  const formattedRecorrido = (recorrido) =>
    recorrido.map((point) => ({
      lat: parseFloat(point.latitud),
      lng: parseFloat(point.longitud),
    }));

  return isLoaded ? (
    <div className='bodydash'>
      <div className='containerdash'>
        <div className='carddash'>
          <h3>Ventas Realizadas</h3>
          <h2 className='h3dash'>{ventasRealizadas}</h2>
        </div>

        <div className='carddash'>
          <h3>Envíos Colectados</h3>
          <h2 className='h3dash'>{enviosColectados}</h2>
        </div>

        <div className='carddash'>
          <h3>Pedidos en Logística</h3>
          <h2 className='h3dash'>{pedidosEnLogistica}</h2>
        </div>

        <div className='carddash'>
          <h3>Pedidos en Ruta</h3>
          <h2 className='h3dash'>{pedidosEnRuta}</h2>
        </div>

        <div className='carddash'>
          <h3>Pedidos Reprogramados</h3>
          <h2 className='h3dash'>{pedidosReprogramados}</h2>
        </div>

        <div className='carddash'>
          <h3>Pedidos Cancelados</h3>
          <h2 className='h3dash'>{pedidosCancelados}</h2>
        </div>
        <div className='carddash'>
          <h3>Choferes</h3>
          <h2 className='h3dash'>{pedidosCancelados}</h2>
        </div>
        <div className='carddash'>
          <h3>Solicitudes</h3>
          <h2 className='h3dash'>{pedidosCancelados}</h2>
        </div>
      </div>

      {/* <div style={{ marginTop: '20px', border: '1px solid gray' }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          onLoad={(map) => (mapRef.current = map)}
          onError={(error) => console.error('Error loading map:', error)}
        >
          {choferes.map(
            (chofer, index) =>
              chofer.gps && (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(chofer.gps.latitud),
                    lng: parseFloat(chofer.gps.longitud),
                  }}
                  title={chofer.nombre}
                  onClick={() => handleShowRecorrido(chofer)}
                />
              ),
          )}
          {showRecorrido && (
            <Polyline
              path={formattedRecorrido(showRecorrido)}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          )}
        </GoogleMap>
      </div> */}
      <div style={{ marginTop: '10px' }}>
        <Camino></Camino>
      </div>
    </div>
  ) : (
    <p>Cargando mapa...</p>
  );
};

export default LogisticaDash;
