import React from 'react';
import './salir.css';
import backgroundImage from './backlogin.png'; // Ajusta la ruta de la imagen según su ubicación

const Salir = () => {
  return (
    <div><br/><br/><br/><br/><h4>Salir</h4>
    <div className="containerSalir" style={{ backgroundImage: `url(${backgroundImage})` , width:"50%"}}>
      <div className="content">
        
      </div>
    </div></div>
  );
};

export default Salir;
