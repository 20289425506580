import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./blogpost.css";

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [comment, setComment] = useState("");
  const [nombre, setNombre] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Realiza una solicitud para obtener el detalle del post con el ID correspondiente
    fetch(`https://www.shipmaster.com.ar/shipmaster/blog/blog.php?id=${id}`) // Modificado para incluir el ID
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPost(data);
          setIsLoading(false);
          fetchComments(data.idblog); // Cargar comentarios después de obtener el post
        } else {
          console.error("No se encontró el post con el ID especificado");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error al cargar los detalles del post", error);
        setIsLoading(false);
      });
  }, [id]);

  // Función para cargar comentarios
  const fetchComments = (idblog) => {
    fetch(
      `https://www.shipmaster.com.ar/php/setraencoments.php?action=fetchcomments&blogid=${idblog}`
    )
      .then((response) => response.json())
      .then((data) => {
        setComments(data);
        setCommentsLoading(false);
      })
      .catch((error) => {
        console.error("Error al cargar los comentarios", error);
        setCommentsLoading(false);
      });
  };

  const handleCommentSubmit = () => {
    if (nombre.trim() === "" || comment.trim() === "") {
      console.error("Nombre y comentario son campos obligatorios");
      return;
    }
  
    const newComment = {
      nombre: nombre,
      comentario: comment,
    };
  
    fetch("https://www.shipmaster.com.ar/php/savecoment.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        postId: id,
        comment: newComment,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // El comentario se guardó exitosamente
          setComment("");
          setNombre("");
          
          // Después de que se haya enviado el comentario, carga los comentarios actualizados
          fetchComments(id);
        } else {
          console.error("Error al guardar el comentario en el servidor");
        }
      })
      .catch((error) => {
        console.error("Error al enviar el comentario al servidor", error);
      });
  };
  

  // ...

  return (
    <div>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <React.Fragment>
            <p style={{ textAlign: "center" }}>
              <img
                src={post.imagen}
                alt={post.titulo}
                style={{ width: "100%", maxWidth: "500px", height: "auto" }}
              />
            </p>
            <h1 style={{ textAlign: "center" }}>{post.titulo}</h1>
            <div
              style={{
                margin: "0 auto", /* Centramos el contenido */
                padding: "0 10px", /* Añadimos espacio lateral en pantallas pequeñas */
                textJustify: "auto",
              }}
            >
              <h4>{post.subtitulo}</h4>
              <p style={{textAlign:"justify"}}>{post.post}</p>
              <p style={{textAlign:"justify"}}>{post.post1}</p>
              <p>{post.enlace}</p>
            </div>
            <div style={{ textAlign: "left", marginLeft: "10%" }}>
              <p style={{ textAlign: "center" }}>
                <h3>Comentarios</h3>
              </p>
              {commentsLoading ? (
                <p>Cargando comentarios...</p>
              ) : (
                <ul style={{ listStyle: "none", padding: 0 ,display: "block", justifyContent: "center"}}>
                  {comments.map((comment, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                        width:"70%",
                        alignSelf:"center"
                      }}
                    >
                      <strong>{comment.nombre}:</strong> {comment.comentario}
                    </li>
                  ))}
                </ul>
              )}
              {
                <div className="comentando-form">
                  <div className="comentando-group">
                    <h4 className="form-title">Ingresa tu comentario</h4>
                    <label htmlFor="nombre">Nombre:</label>
                    <input
                      type="text"
                      id="nombre"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      placeholder="Ingresa tu nombre"
                    />
                  </div>
                  <div className="comentando-group">
                    <label htmlFor="comment">Comentario:</label>
                    <textarea
                      id="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Escribe un comentario..."
                    />
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() => navigate("/userDash")}
                      className="button-secondary"
                    >
                      <i className="fas fa-arrow-left"></i> Volver al Blog
                    </button>
                    <button
                      onClick={handleCommentSubmit}
                      className="button-primary"
                    >
                      <i className="fas fa-save"></i> Comentar
                    </button>
                  </div>
                </div>
              }
            </div>
          </React.Fragment>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="footer" style={{ width: "100%" }}>
        <div style={{ backgroundColor: "#1CD10E" }}>
          <p
            style={{ textAlign: "center", fontSize: "24px", color: "#FFFFFF" }}
          >
            shipmaster - Tecnologia en shipmaster
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
