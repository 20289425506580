import React, { useEffect, useState } from "react";
import JsonData from "../../data/data.json";
import Logistic from "../dashboards/logistica";
 
//import "./logistica.css";

const PortalLogistica = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [userId, setUserId] = useState("");  // id de usuario
  const [userNombre, setUserNombre] = useState("");  // nombre usuario
  const [id_logis, setId_logis] = useState("");  // id logistica
  const [Name_Logistica, setName_Logistica] = useState(localStorage.getItem('nombre_logistica'));
  //const [userMail, setUserMail] = useState("");  // mail de usuario
  
  useEffect(() => {  
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setId_logis(localStorage.getItem("id_logis"));
    //setUserMail(localStorage.getItem("userMail"));
    console.log("userId:" + userId)
    console.log('id logistica:' + id_logis);
    setLandingPageData(JsonData);
  }, [userId, userNombre, id_logis]);

  return (
    <div className='fondodash' style={{ overflowY: 'none' }}>
      <div className='centrado' style={{ marginTop: '1px', width: '100%' }}>
        <div className='centro'>
          <Logistic data={landingPageData.Logistic} />
        </div>
      </div>
    </div>
  );
};

export default PortalLogistica;
