import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import './generar.css';

const Generar = () => {
  // Estado del formulario
  const [formData, setFormData] = useState({
    factura: '',
    descripcion: '',
    direccion: '',
    piso: '',
    departamento: '',
    altura: '',
    cp: '',
    localidad: '',
    barrio: '',
    comuna: '',
    telefono: '',
    nombreContacto: '',
    observaciones: '',
    mailContacto: '',
    domicilioTipo: '',
    horarioEntrega: '',
    origen_id: '',
    origen_nombre: '',
    id_tienda: '',
  });

  // Otros estados
  const [userId, setUserId] = useState('');
  const [userNombre, setUserNombre] = useState('');
  const [nameTienda, setNameTienda] = useState('');
  const [direccionTienda, setDireccionTienda] = useState('');
  const [telefonoTienda, setTelefonoTienda] = useState('');
  const [contacto_tienda, setContactoTienda] = useState('');
  const [idTienda, setidTienda] = useState('');
  const [nombre_logistica, setnombreLogistica] = useState('');
  const [mail_logistica, setmailLogistica] = useState('');
  const [direccion, setDireccion] = useState('');
  const [altura, setAltura] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [currentSuggestion, setCurrentSuggestion] = useState('');
  const [cp, setPostalCode] = useState('');
  const [commune, setCommune] = useState('');
  const [barrio, setbarrio] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [nombreContacto, setnombreContacto] = useState('');
  const [domicilioTipo, setdomicilioTipo] = useState('');
  const [generatedData, setGeneratedData] = useState({
    id_generar: '',
    hash: '',
    hashCtrl: '',
    pin: '',
  });
  const [qrData, setQrData] = useState({});
  const [qrsData, setQrsData] = useState({});
  const [qrCtrl, setQrCtrl] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Ref para el campo de dirección
  const inputRef = useRef(null);

  useEffect(() => {
    // Inicializar los datos del usuario y tienda desde localStorage
    setUserId(localStorage.getItem('userId'));
    setUserNombre(localStorage.getItem('userNombre'));
    setNameTienda(localStorage.getItem('name_tienda'));
    setDireccionTienda(localStorage.getItem('direccion_tienda'));
    setTelefonoTienda(localStorage.getItem('telefono_tienda'));
    setnombreLogistica(localStorage.getItem('nombre_logistica'));
    setmailLogistica(localStorage.getItem('mail_logistica'));
    setContactoTienda(localStorage.getItem('contacto_tienda'));
    setidTienda(localStorage.getItem('id_tienda'));
  }, []);

  useEffect(() => {
    if (formData.direccion.length > 2) {
      fetch(
        `https://shipmaster.com.ar/backoffice/php/admindash/codigospostales.php?query=${encodeURIComponent(
          formData.direccion,
        )}`,
      )
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
        })
        .catch((err) => setError('Error fetching suggestions: ' + err.message));
    } else {
      setSuggestions([]);
    }
  }, [formData.direccion]);

  useEffect(() => {
    if (formData.altura && formData.direccion) {
      fetch(
        `https://shipmaster.com.ar/backoffice/php/admindash/codigospostales.php?street=${encodeURIComponent(
          formData.direccion,
        )}&altura=${encodeURIComponent(formData.altura)}`,
      )
        .then((response) => response.json())
        .then((data) => {
          setPostalCode(data.codigo_postal || '');
          setCommune(data.comuna || '');
          setbarrio(data.barrio || '');
          setbarrio(data.cp || '');
          setLocalidad(data.localidad || '');
        })
        .catch((err) => setError('Error fetching address details: ' + err.message));
    }
  }, [formData.altura, formData.direccion]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && currentSuggestion) {
      setFormData((prevData) => ({ ...prevData, direccion: currentSuggestion }));
      setSuggestions([]);
      setCurrentSuggestion('');
      e.preventDefault();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData((prevData) => ({ ...prevData, direccion: suggestion }));
    setSuggestions([]);
  };

  const fecha = new Date().toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const generateRandomPin = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };

  const transformString = (str) => {
    const currentMinutes = new Date().getMinutes();
    return str.split('').reverse().join('') + currentMinutes;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'factura',
      'descripcion',
      'direccion',
      'altura',
      'piso',
      'departamento',
      'cp',
      'localidad',
      'barrio',
      'comuna',
      'telefono',
      'nombreContacto',
      'mailContacto',
      'observaciones',
      'domicilioTipo',
      'horarioEntrega',
    ];
    const hasEmptyField = requiredFields.some((field) => formData[field] === '');

    if (hasEmptyField) {
      setErrorMessage('Por favor, complete todos los campos obligatorios.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const id_generar = Date.now().toString();
      const hash = `SQR${formData.origen_id.padStart(3, '0')}${id_generar}`;
      const hashs = `${formData.origen_id.padStart(3, '0')}${id_generar}`;
      const hashCtrl = `CQR${transformString(formData.origen_id.padStart(3, '0') + id_generar)}`;
      const pin = generateRandomPin();

      const qrData = {
        QR_: hash,
      };

      const qrsData = {
        hashs,
      };

      const qrCtrl = {
        hashCtrl,
      };

      const saveResponse = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/generar.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          id_generar,
          hash,
          hashCtrl,
          pin,
          id_chofer: userId,
          estado: 1,
        }),
      });

      if (!saveResponse.ok) {
        throw new Error('Error al guardar los datos');
      }

      const result = await saveResponse.json();

      setGeneratedData({
        id_generar,
        hash,
        hashCtrl,
        pin,
      });

      setQrData(qrData);
      setQrsData(qrsData);
      setQrCtrl(qrCtrl);
      setModalIsOpen(true);
      await fetch('https://shipmaster.com.ar/backoffice/php/mail/generate_qr.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          qrCtrl: qrCtrl.hashCtrl,
        }),
      });

      const mailResponse = await fetch('https://shipmaster.com.ar/backoffice/php/mail/sendmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          para: formData.mailContacto,
          asunto: 'Informacion del envio',
          cuerpo: `
            <h3>¡Gracias por tu compra en ${nameTienda}!</h3>
            <p>Tu pedido ha sido recibido y está en proceso de preparación.</p>
            <p><strong>Dirección de Entrega:</strong> ${formData.direccion} piso:${formData.piso} dpto:${formData.departamento}</p>
            <h5> >>>>> IMPORTANTE <<<<< </h5>
            <p>Para garantizar que tu pedido llegue a la persona correcta, el repartidor te solicitará este código QR</p>
            <p><img src="https://shipmaster.com.ar/backoffice/php/mail/qrimage/qr_code.png" alt="Código SQR" style="max-width: 200%; height: auto;" /></p>
            <p>Asegúrate de tenerlo a mano.</p>
            <p>En caso de que otra persona reciba el pedido, este es el PIN que le tiene que dar al repartidor:</p>
            <p><strong>Tu código PIN es:</strong><h3>${pin}</h3></p>
            <p>¡Gracias por confiar en nosotros!</p>
            <p>Atentamente,<br>El equipo de ${nameTienda} & ShipMaster</p>
          `,
        }),
      });

      if (!mailResponse.ok) {
        throw new Error('Error al enviar el correo');
      }

      setSuccessMessage('¡Etiqueta generada y correo enviado con éxito!');
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className='containerGenerar'>
      <div>
        <h3>Generar Etiqueta de Envío - usuario: {userId}</h3>
      </div>
      <div style={{ fontSize: 25, color: 'black', backgroundColor: 'green' }}>
        {isLoading && <p>Loading...</p>}
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
        {successMessage && <p className='success-message'>{successMessage}</p>}
      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-containerGenerar'>
          <div classname='form.containerGenerar'>
            <div className='form-groupGenerar'>
              <label>Factura:</label>
              <input
                type='text'
                name='factura'
                className='input-gris-oscuro'
                value={formData.factura}
                onChange={handleChange}
              />
              <label>Descripcion:</label>
              <input
                type='text'
                name='descripcion'
                className='input-gris-oscuro'
                value={formData.descripcion}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-groupGenerar'>
            <table>
              <tr>
                <td>
                  <label>Dirección:</label>
                </td>
                <td>
                  <label>altura:</label>
                </td>
                <td>
                  {' '}
                  <label>Piso:</label>
                </td>
                <td>
                  {' '}
                  <label>Departamento:</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type='text'
                    name='direccion'
                    value={formData.direccion}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder='Dirección'
                    ref={inputRef}
                  />
                  {suggestions.length > 0 && (
                    <ul
                      style={{
                        position: 'absolute',
                        margin: 0,
                        padding: 0,
                        listStyle: 'none',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        zIndex: 1000, // Asegúrate de que la lista esté por encima de otros elementos
                      }}
                    >
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            backgroundColor: direccion === suggestion.nombre_calle ? '#e0e0e0' : '#fff',
                            color: '#000', // Cambié el color para que sea más visible
                          }}
                          onClick={() => handleSuggestionClick(suggestion.nombre_calle)}
                        >
                          {suggestion.nombre_calle}
                        </li>
                      ))}
                    </ul>
                  )}
                </td>
                <td>
                  <input
                    type='text'
                    name='altura'
                    value={formData.altura}
                    onChange={handleChange}
                    placeholder='Altura'
                  />
                </td>
                <td>
                  <input
                    className='input-gris-oscuro'
                    type='text'
                    name='piso'
                    value={formData.piso}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <input
                    className='input-gris-oscuro'
                    type='text'
                    name='departamento'
                    value={formData.departamento}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </table>
          </div>
          <div className='form-groupGenerar'>
            <table>
              <tr>
                <td>
                  <label>Localidad:</label>
                </td>
                <td>
                  <label>Barrio:</label>
                </td>
                <td>
                  <label>Comuna:</label>
                </td>
                <td>
                  <label>Código Postal:</label>
                </td>
              </tr>
              <tr>
                <td>
                  {/* <input
                        type='text'
                        name='Localidad'
                    
                        value={formData.localidad}
                        onChange={handleChange}
                      /> 
                      <input type='text' placeholder='Localidad' value={localidad} readOnly />*/}
                  <input
                    type='text'
                    name='localidad'
                    className='input-gris-oscuro'
                    value={formData.localidad}
                    onChange={handleChange}
                  />
                </td>
                <td>
                  <input
                    type='text'
                    name='barrio'
                    className='input-gris-oscuro'
                    value={formData.barrio}
                    onChange={handleChange}
                  />

                  {/* <input type='text' placeholder='barrio' value={barrio} readOnly /> */}
                </td>
                <td>
                  <input
                    type='text'
                    name='comuna'
                    className='input-gris-oscuro'
                    value={formData.comuna}
                    onChange={handleChange}
                  />
                  {/* <input
                        type='text'
                        name='comuna'
                      
                        value={formData.comuna}
                        onChange={handleChange}
                      /> */}
                  {/* <input style={{ color: 'black' }} type='text' placeholder='Commune' value={commune} readOnly /> */}
                </td>
                <td>
                  {/* <input
                        type='text'
                        name='cp'
                        className='input-gris-oscuro'
                        value={formData.cp}
                        onChange={handleChange}
                      /> 
                      <input
                        style={{ color: 'black' }}
                        type='text'
                        placeholder='Postal Code'
                        value={postalCode}
                        readOnly
                      />*/}
                  <input
                    type='text'
                    name='cp'
                    className='input-gris-oscuro'
                    value={formData.cp}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </table>
          </div>
          <div className='form-groupGenerar'>
            <label>Contacto:</label>
            <input
              type='text'
              name='nombreContacto'
              className='input-gris-oscuro'
              value={formData.nombreContacto}
              onChange={handleChange}
            />
            <label>Telefono</label>
            <input
              type='text'
              name='telefono'
              className='input-gris-oscuro'
              value={formData.telefono}
              onChange={handleChange}
            />
          </div>
          <div className='form-groupGenerar'>
            <label>Observaciones:</label>
            <input
              type='text'
              name='observaciones'
              className='input-gris-oscuro'
              value={formData.observaciones}
              onChange={handleChange}
            />
          </div>
          <div className='form-groupGenerar'>
            <label>Correo Electrónico:</label>
            <input
              type='email'
              name='mailContacto'
              className='input-gris-oscuro'
              value={formData.mailContacto}
              onChange={handleChange}
            />
          </div>
          <div className='form-groupGenerar'>
            <label>Domicilio Tipo:</label>
            <select
              name='domicilioTipo'
              className='input-gris-oscuro'
              value={formData.domicilioTipo}
              onChange={handleChange}
            >
              <option value=''>Seleccione</option>
              <option value='Laboral'>Laboral</option>
              <option value='Particular'>Particular</option>
            </select>
          </div>
          <div className='form-groupGenerar'>
            <label>Horario de Entrega:</label>
            <select
              name='horarioEntrega'
              className='input-gris-oscuro'
              value={formData.horarioEntrega}
              onChange={handleChange}
            >
              <option value=''>Seleccione</option>
              <option value='8-13'>8 a 13 hs</option>
              <option value='13-21'>13 a 21 hs</option>
            </select>
          </div>
          <button type='submit' className='btn-submitGenerar' disabled={isLoading} style={{ color: 'black' }}>
            {isLoading ? 'Generando...' : 'Generar Etiqueta'}
          </button>
        </div>
      </form>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel='Etiqueta Generada'
          className='print-modal'
          style={{
            content: {
              width: '500px',
              height: 'auto',
              margin: 'auto',
              border: '4px solid black',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              scrollbarWidth:true,
            },
          }}
        >
          <h3 style={{ textAlign: 'center' }}>
            <img
              src='https://shipmaster.com.ar/img/logo/logo3d.png'
              alt='QR'
              style={{ maxWidth: '30%', height: 'auto', maxHeight: '50px' }}
            />{' '}
            XQR
          </h3>
          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              textAlign: 'center',
              padding: '5px',
              fontSize: '20',
            }}
          >
            PEGAR EN UN LUGAR VISIBLE
          </div>
          <div>
            <table style={{ width: '100%', tableLayout: 'fixed' }}>
              <tr>
                <td style={{ width: '40%', paddingLeft: '0%', verticalAlign: 'center' }}>
                  <QRCode value={JSON.stringify(qrCtrl)} />
                </td>
                <td style={{ width: '60%', paddingRight: '0%', verticalAlign: 'top' }}>
                  <h4 style={{ textAlign: 'center' }}>ORIGEN</h4>
                  <p>
                    {nameTienda} - {idTienda}
                  </p>
                  <p>Direccion: {direccionTienda}</p>
                  <p>Tel: {telefonoTienda}</p>
                </td>
              </tr>
            </table>
          </div>
          <hr style={{ border: '1px solid black', width: '100%', background: 'black' }} />
          <div>
            <h4 style={{ textAlign: 'center' }}>DESTINO</h4>
            <h5>
              Comuna: {formData.comuna.charAt(0).toUpperCase() + domicilioTipo.slice(1)} - Barrio: {formData.barrio} -
              Domicilio: {formData.domicilioTipo}
            </h5>
            <h5 style={{ textAlign: 'center' }}>Horario Entrega: {formData.horarioEntrega} </h5>
            <h5 style={{ textAlign: 'center' }}>
              {formData.direccion} {formData.altura} - Piso: {formData.piso} - Dpto: {formData.departamento}
            </h5>

            <h5 style={{ textAlign: 'center' }}>
              Nombre: {nombreContacto} Tel:{formData.telefono}
            </h5>
            <h5>Observaciones: {formData.observaciones}</h5>
          </div>
          <hr style={{ border: '1px solid black', width: '100%', background: 'black' }} />
          <div>
            <table style={{ width: '100%', tableLayout: 'fixed', padding: '100px' }}>
              <tbody>
                <tr>
                  <td style={{ width: '40%', paddingLeft: '5px', verticalAlign: 'top' }}>
                    <h5 style={{ textAlign: 'center' }}>XQR Tracking: {generatedData.hash}</h5>
                    <p>{fecha}</p>
                  </td>
                  <td style={{ width: '60%', paddingRight: '5px', textAlign: 'center', verticalAlign: 'top' }}>
                    <QRCode value={JSON.stringify(qrData)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                marginTop: '20px',
              }}
            >
              <button
                onClick={closeModal}
                style={{
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: '#007bff',
                  color: 'white',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
              >
                Cerrar
              </button>
              <button
                onClick={() => window.print()}
                style={{
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: '#28a745',
                  color: 'white',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#218838')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#28a745')}
              >
                Imprimir
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Generar;
