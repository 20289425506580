import React, { useState, useEffect } from "react";
import EditForm from "./cruds/EditForm";
import "./admin.css";

import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [data, setData] = useState([]);

  const [selectedTable, setSelectedTable] = useState("usuarios");
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false); // Variable para mostrar botones en filas

  const fetchData = (table) => {
    fetch(
      `https://www.shipmaster.com.ar/backoffice/php/admindash/admintabla${table}.php?action=fetch${table}`
    )
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
    fetchData(tableName);
  };

  useEffect(() => {
    fetchData(selectedTable);
  }, [selectedTable]);

  const handleSave = (newData) => {
    fetch(
      `https://www.shipmaster.com.ar/backoffice/php/admindash/admintabla${selectedTable}.php?action=add${selectedTable}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          fetchData(selectedTable); // Actualizar datos después de agregar
          setIsEditing(false);
          setIsAdding(false);
        }
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const handleSaveOrUpdate = (newData) => {
    if (isAdding) {
      handleSave(newData); // Llama a la función handleSave si estás agregando un nuevo registro
    } else {
      handleUpdate(newData); // Llama a la función handleUpdate si estás editando un registro existente
    }
  };

  const getFormFields = (selectedTable) => {
    // Define los campos del formulario según la tabla seleccionada
    switch (selectedTable) {
      case "usuarios":
        return [
          { name: "Id_usuario", label: "ID Usuario", type: "text" },
          { name: "Usuario", label: "Usuario", type: "text" },
          { name: "Password", label: "Contraseña", type: "password" },
          { name: "jerarquia", label: "jerarquia", type: "text" },
          { name: "rol", label: "rol", type: "text" },
          { name: "mail", label: "mail", type: "text" },
          // Define más campos según tus necesidades
        ];
      case "logisticas":
        return [
          { name: "id_logistica", label: "id_logistica", type: "text" },
          { name: "id_jerarquia", label: "id_jerarquia", type: "text" },
          { name: "nombre_logistica", label: "Nombre Logistica", type: "text" },
          { name: "cuit_logistica", label: "CUIT", type: "text" },
          { name: "mail_logistica", label: "Mail", type: "text" },
          { name: "contacto_logistica", label: "Contacto", type: "text" },
          { name: "telefono_logistica", label: "Telefono", type: "text" },
          { name: "direccion_logistica", label: "Direccion", type: "text" },
          { name: "fechayhora", label: "fecha y hora", type: "text" },

          // Define más campos según tus necesidades
        ];
      case "tienda":
        //`id_tienda`, `id_logistica`, `id_usuario`, `nombre_tienda`, `direccion_tienda`, `mail_tienda`, `telefono_tienda`, `cuit_tienda`, `contacto_tienda`, `fechayhora`
        return [
          { name: "id_tienda", label: "id_tienda", type: "text" },
          { name: "id_logistica", label: "Logistica", type: "text" },
          { name: "id_usuario", label: "id_usuario", type: "text" },
          { name: "nombre_tienda", label: "tienda", type: "text" },
          { name: "direccion_tienda", label: "Direccion", type: "text" },
          { name: "mail_tienda", label: "Mail", type: "text" },
          { name: "telefono_tienda", label: "Telefono", type: "text" },
          { name: "cuit_tienda", label: "CUIT", type: "text" },
          { name: "contacto_tienda", label: "Contacto", type: "text" },
          { name: "fechayhora", label: "fecha y hora", type: "text" },

          // Define más campos según tus necesidades
        ];
      default:
        return [];
    }
  };
  const tableHeaderStyles = {
    usuarios: {
      backgroundColor: "#409cff",
    },
    logistica: {
      backgroundColor: "#33cc8a",
    },
    // Añade estilos para otras tablas si es necesario
  };


  const renderTableHeaders = () => {
    const headers = {
      usuarios: [
        { label: "Id_usuario", text: "ID Usuario" },
        { label: "Usuario", text: "Usuario" },
        { label: "Password", text: "Contraseña" },
        { label: "jerarquia", text: "Jerarquía" },
        { label: "Rol", text: "Rol" },
        { label: "Mail", text: "Mail" },
        { label: "Fecha", text: "Fecha" },
      ],
      logisticas: [
        { label: "id_logistica", text: "ID Logistica" },
        { label: "id_jerarquia", text: "ID Jerarquía" },
        { label: "nombre_logistica", text: "Nombre" },
        { label: "CUIT", text: "CUIT" },
        { label: "Mail", text: "Mail" },
        { label: "contacto", text: "Contacto" },
        { label: "telefono", text: "Telefono" },
        { label: "Direccion", text: "Dirección" },
        { label: "fecha y hora", text: "Fecha y Hora" },
      ],
      tienda: [{ label: "id_tienda", text: "id_tienda" },
      { label: "Logistica", text: "Logistica" },
      { label: "id_usuario", text: "id_usuario" },
      { label: "tienda", text: "tienda" },
      { label: "Direccion", text: "Direccion" },
      { label: "Mail", text: "Mail" },
      { label: "Telefono", text: "Telefono" },
      { label: "CUIT", text: "CUIT" },
      { label: "Contacto", text: "Contacto" },
      { label: "fecha y hora", text: "fecha y hora" },
      ],
      // Añade más headers para otras tablas si es necesario
    };

    return (
      <tr>
        {headers[selectedTable].map((header) => (
          <th
            key={header.label}
            style={{
              ...tableHeaderStyles[selectedTable],
              color: "white",
              textAlign: "center",
            }}
          >
            {header.text}
          </th>
        ))}
        <th style={{ ...tableHeaderStyles[selectedTable], color: "white", textAlign: "center" }}>
          Opciones
        </th>
      </tr>
    );
  };

  const renderTableRows = () => {
    // Render table rows based on data and selected table
    return data.map((row, index) => (
      <tr key={index}>
        {/* Map data properties to table cells based on selected table */}
        {Object.keys(row).map((key) => (
          <td
            key={key}
            style={{
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row[key]}
          </td>
        ))}
        <td>
          {isAddingRow ? (
            <button onClick={() => handleSave(row)}>Guardar</button>

          ) : (
            <>
              <button onClick={() => handleEdit(row)}>Editar</button>
              <button onClick={() => handleDelete(row)}>Eliminar</button>
              <button onClick={() => handleAdd()}>Agregar</button>
            </>
          )}
        </td>
      </tr>
    ));
  };

  const handleAdd = () => {
    setIsEditing(true);
    setIsAddingRow(true); // Mostrar botones en las filas
    setIsAdding(true); // Indicar que se agrega un nuevo registro en el formulario
    setEditData({}); // Reinicia los datos del formulario
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditData(data);
  };

  const handleUpdate = (updatedData) => {
    // Actualizar los datos en el estado
    const updatedArray = data.map((item) =>
      item.Id_usuario === updatedData.Id_usuario ? updatedData : item
    );
    setData(updatedArray);
    setIsEditing(false);
    setEditData(null);
  };

  const handleDelete = (data) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar este registro?"
    );
    if (confirmed) {
      fetch(
        `https://www.shipmaster.com.ar/backoffice/php/admindash/admintabla${selectedTable}.php?action=delete${selectedTable}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id:
              data.id_usuario ||
              data.id_logistica ||

              data.id,
          }),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            const updatedArray = data.filter(
              (item) =>
                item.id_usuario !== data.id_usuario ||
                item.id_logistica !== data.id_logistica ||
                item.id !== data.id
            );
            setData(updatedArray);
          }
        })
        .catch((error) => console.error("Error deleting data:", error));
    }
  };

  let navigate = useNavigate();
  function Salir() {
    console.log("dentro del handle go()");
    //historyObj.push("../AdminDash");
    navigate("../Usuarios2");
  }

  return (
    <div>
      <br />
      <div class="container">
        <div class="left-div">
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>
              <i className="fas fa-cogs" style={{ fontSize: "34px" }}></i>
              DASHBOARD ADMINISTRACION
            </h2>
          </div>
        </div>
        <div class="right-div">
          <button class="exit-button" onClick={Salir}>
            Salir
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
      <div className="section-buttons">
        <button
          className="section-button"
          onClick={() => handleTableChange("usuarios")}
        >
          <i className="fas fa-users"></i> Usuarios
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("logisticas")}
        >
          <i className="fas fa-leaf"></i> Logisticas
        </button>
        <button
          className="section-button"
          onClick={() => handleTableChange("tienda")}
        >
          <i className="fas fa-leaf"></i> Tiendas
        </button>
        <div></div>
      </div>

      <br />
      <table>
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
      {isEditing && (
        <EditForm
          data={editData}
          table={selectedTable}
          onClose={() => {
            setIsEditing(false);
            setIsAdding(false); // Asegurarse de que isAdding se restablezca
          }}
          onSaveOrUpdate={handleSaveOrUpdate} // Pasa la función handleSaveOrUpdate
          isAdding={isAdding}
          fields={getFormFields(selectedTable)} // Pasa los campos del formulario
        />
      )}
      <br /><br /><br /><br /><br />
    </div>
  );
};

export default Admin;
