import React, { useState } from "react";

const EditForm = ({ data, table, onClose, onSaveOrUpdate, isAdding, fields }) => {
  const [editedData, setEditedData] = useState(isAdding ? {} : { ...data });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    onSaveOrUpdate(editedData); // Llama a la función para guardar o actualizar
    onClose();
  };

  return (
    <div>
      <div>
        <h3>{isAdding ? "Agregar Registro" : "Editar Registro"}</h3>
        <form>
          {fields.map((field) => (
            <div key={field.name}>
              <label>{field.label}</label>
              <input
                type={field.type}
                name={field.name}
                value={editedData[field.name] || ""}
                onChange={handleInputChange}
              />
            </div>
          ))}
        </form>
      </div>
      <div>
        <button onClick={handleSave}>
          {isAdding ? "Agregar" : "Guardar Cambios??? Seguro??"}
        </button>
        <button onClick={onClose}>Cancelar</button>
      </div>
    </div>
  );
};
export default EditForm;
