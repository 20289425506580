import React from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>XQR</span> <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span> <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/#page-top'>
            <img
              src='../img/logo/logoShipMaster.png'
              width='80'
              style={{ position: 'relative', top: '-30px' }}
              alt='shipmaster'
            />
          </a>
        </div>

        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='/#about' className='page-scroll'>
                Acerca
              </a>
            </li>
            {/*<li>
              <a href="/#Descripcion" className="page-scroll">
                Descripcion
              </a>
            </li> */}

            <li>
              <a href='/#team' className='page-scroll'>
                Team
              </a>
            </li>
            <li>
              <a href='/#contact' className='page-scroll'>
                Contacto
              </a>
            </li>
            {/*<li>
              <Link to="/Campus">Campus</Link>
            </li>
            <li>
              <Link to="/Usuarios">Usuarios</Link>
            </li>*/}
            <li
              style={{
                listStyleType: 'none', // Elimina los puntos de la lista
                margin: '1px 0', // Espacio vertical entre elementos de la lista
                padding: '1px', // Espacio dentro del elemento de la lista
                backgroundColor: '#c076dd', // Color de fondo del elemento de la lista
                borderRadius: '5px', // Bordes redondeados
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra ligera
                transition: 'background-color 0.3s, transform 0.3s', // Transiciones suaves para efectos hover
                justifyContent: 'flex-end',
              }}
            >
              <Link
                to='/Usuarios2'
                style={{
                  textDecoration: 'none', // Quita el subrayado del enlace
                  color: '#333', // Color del texto
                  fontWeight: 'bold', // Texto en negrita
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = '#007bff'; // Color de texto al pasar el mouse
                  e.target.parentNode.style.backgroundColor = '#e0e0e0'; // Color de fondo al pasar el mouse
                  e.target.parentNode.style.transform = 'scale(1.02)'; // Escalar ligeramente el elemento al pasar el mouse
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = '#333'; // Color de texto por defecto
                  e.target.parentNode.style.backgroundColor = '#c076dd'; // Color de fondo por defecto
                  e.target.parentNode.style.transform = 'scale(1)'; // Restaurar tamaño por defecto
                }}
              >
                Usuarios
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
