import React from "react";
import { useState, useEffect } from "react";
import JsonData from "../../data/data.json";
import Rider from "../dashboards/rider";

//import "./shipmaster.css";

const PortalRider = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [userId, setUserId] = useState("");  // id de usuario
  const [userNombre, setUserNombre] = useState("");  // nombre usuario
  //const [userMail, setUserMail] = useState("");  // mail de usuario
  
  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    //setUserMail(localStorage.getItem("userMail"));
    console.log("userId:" + userId)
    setLandingPageData(JsonData);
  }, [userId, userNombre]);

  return (
    <div className="fondodash" style={{ overflowY: "none" }}>
      <div className="centrado">
        <br />
        <br />
        <br /><br /><br /><br /><br />
        <div className="centro">
          <h4>PORTAL RIDER usuario: {userNombre}</h4>
          <Rider data={landingPageData.Rider} />
        </div>
      </div>
    </div>
  );
};

export default PortalRider; 