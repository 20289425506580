import React, { useEffect, useState } from "react";

import Adminppal from "../dashboards/adminppal";

import JsonData from "../../data/data.json";

import "./usuarios.css";

const PortalAdmin = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [userId, setUserId] = useState("");  // id de usuario
  const [userNombre, setUserNombre] = useState("");  // nombre usuario
  //const [userMail, setUserMail] = useState("");  // mail de usuario
  
  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    //setUserMail(localStorage.getItem("userMail"));
    console.log("userId:" + userId)
    setLandingPageData(JsonData);
  }, [userId, userNombre]);

  return (
    <div className="fondodash" style={{ overflowY: "none" }}>
      <div className="centrado">
        <br />
        <br />
        <br /><br /><br />
        <div className="centro">
          <h4>PORTAL ADMINISTRADOR SHIPMASTER</h4>
          <p>Usuario:{userNombre}</p>
          <Adminppal data={landingPageData.Adminppal} />
        </div>
      </div>
    </div> 
  );
};
export default PortalAdmin;
