import React, { useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import "./usuarios.css";

const Usuarios2 = () => {
  const [usuario, setUsuario] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [hwidLog, setHwidLog] = useState("");
  const [roleData, setRole] = useState("");
  const [emailData, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleGo() {
    console.log("dentro del handle go()");
    navigate("/adminDash");
  }
  function handleGoUsr() {
    console.log("dentro del handle goUsr()");
    navigate("/userDash");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true); // Mostrar el loading

    // Envío de los datos al archivo PHP
    //fetch("http://localhost/shipmaster/php/comandos.php", {
    fetch("https://www.shipmaster.com.ar/php/comandos.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usuario: usuario,
        contrasena: contrasena,
      }),
    })
      .then((response) => response.json()) // Convertir la respuesta a un objeto JSON
      .then((data) => {
        if (data.success) {
          setMensaje("¡Inicio de sesión exitoso!");
          // Mostrar los datos del usuario
          console.log("Datos del usuario:", data.usuario);
          console.log("Datos del rol:", data.usuario.role);
          //guardo variables en el hook
          setEmail(data.usuario.email);
          setRole(data.usuario.role);
          setHwidLog(data.usuario.mac);
          localStorage.setItem("userName", usuario); //datauser
          localStorage.setItem("macData", data.usuario.mac); //data mac user
          console.log("mac: " + hwidLog);
          console.log("role: " + roleData);
          console.log("email: " + emailData);

          if (data.usuario.role === "1") {
            // aca va el enlace para admin web
            console.log("aca va el enlace para admin web");
            handleGo();
          }
          if (data.usuario.role === "5") {
            // aca va el enlace para usuario
            console.log("aca va el enlace para usuario");
            handleGoUsr();
          }
        } else {
          setMensaje(data.mensaje);
        }
        setLoading(false); // Ocultar el loading
      })
      .catch((error) => {
        console.log("Error de análisis JSON:", error);
      });
  };

  return (
    <div className="usuarios2ppal">
      <div
        style={{
          margin: "100px 0% 0% 5%",
        }}
      >
        <h1
          style={{
            display: "flex",
            height: "auto",
            width: "300px",
            margin: "auto",
          }}
        >
          Descripcion
        </h1>
      </div>
      {/* <h3>Pantalla de login </h3> */}
      <br />

      <div>
        <div className="login" style={{ alignItems: "center" }}>
          <br />
          <div className="loginCard">
            <h1>Login</h1>
            <br></br>
            <div className="loginCampo">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
                <br />
                <input
                  style={{ marginTop: "5px" }}
                  type="password"
                  value={contrasena}
                  onChange={(e) => setContrasena(e.target.value)}
                />
                <br />
                <br />
                <button type="submit">Iniciar sesión</button>
                {loading && (
                  <div className="loading-overlay">
                    <div className="loading-container">
                      <ReactLoading type="spinningBubbles" color="#0592F9" />
                    </div>
                  </div>
                )}{" "}
                <p>{mensaje}</p>
              </form>
              <br />
            </div>

            <p className="footerLogin">
              Ingresa y mira algunos datos recopilados
            </p>
            <h5>Usuario: test - Password:test</h5>
          </div>
        </div>
      </div>
      <div className="footer">
        <p>XQR - Tecnologia en E-Logistica. </p>
      </div>
    </div>
  );
};
export default Usuarios2;
