import React, { useState } from "react";

export const About = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setImageSrc("");
    setShowModal(false);
  };

  return (
    <div id='about' className='aboutback'>
      <div className='section-title'>
        <h2 style={{ textAlign: 'center' }}>Acerca de XQR</h2>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <p className='text-justificado'>
                {props.data && props.data.paragraph1 ? (
                  <div dangerouslySetInnerHTML={{ __html: props.data.paragraph1 }} />
                ) : (
                  'Cargando...'
                )}
              </p>

              <p />
            </div>
          </div>
          <div>
            <div className='col-xs-12 col-md-4'>
              <div className='row'>
                <div className='col-xs-6'>
                  <div>
                    <img
                      src='img/about/about1.jpg'
                      className='portfolio-img'
                      alt=''
                      width='230px'
                      height='230px'
                      style={{ borderRadius: '8px 8px 8px 8px' }}
                      onClick={() => openModal('img/about/about1.jpg')}
                    />
                    {showModal && (
                      <div className='modal'>
                        <span className='close' onClick={closeModal}>
                          &times;
                        </span>
                        <img src={imageSrc} alt='' />
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-xs-6'>
                  <div>
                    <img
                      src='img/about/about2.jpg'
                      className='img-responsive'
                      alt=''
                      width='230px'
                      height='230px'
                      style={{ borderRadius: '8px 8px 8px 8px' }}
                      onClick={() => openModal('img/about/about2.jpg')}
                    />
                    {showModal && (
                      <div className='modal'>
                        <span className='close' onClick={closeModal}>
                          &times;
                        </span>
                        <img src={imageSrc} alt='' />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-md-4'>
              <div className='row'>
                <div className='col-xs-6'>
                  <div>
                    <img
                      src='img/about/about3.jpg'
                      className='img-responsive'
                      alt=''
                      width='230px'
                      height='230px'
                      style={{ borderRadius: '8px 8px 8px 8px' }}
                      onClick={() => openModal('img/about/about3.jpg')}
                    />
                    {showModal && (
                      <div className='modal'>
                        <span className='close' onClick={closeModal}>
                          &times;
                        </span>
                        <img src={imageSrc} alt='' />
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-xs-6'>
                  <div>
                    <img
                      src='img/about/about5.jpg'
                      className='img-responsive'
                      alt=''
                      width='230px'
                      height='230px'
                      style={{ borderRadius: '8px 8px 8px 8px' }}
                      onClick={() => openModal('img/about/about5.jpg')}
                    />
                    {showModal && (
                      <div className='modal'>
                        <span className='close' onClick={closeModal}>
                          &times;
                        </span>
                        <img src={imageSrc} alt='' />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
                {/* Agrega más imágenes aquí en la misma estructura */}
              </div>
            </div>

            <div className='col-xs-12 col-md-4'>
              <div className='row'>{/* Agrega más imágenes aquí en la misma estructura */}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
