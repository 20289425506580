import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import './usuarios.css';

const Usuarios2 = () => {
  const [usuario, setUsuario] = useState('usuario'); //predefino usuario y clave para el inicio
  const [contrasena, setContrasena] = useState('******'); //predefino usuario y clave para el inicio
  const [mensaje, setMensaje] = useState('');
  const [roleData, setRole] = useState('');
  const [userId, setUserId] = useState(''); // id de usuario
  const [userNombre, setUserNombre] = useState(''); // nombre usuario
  const [userMail, setUserMail] = useState(''); // mail de usuario
  const [Idlogis, setid_Logistica] = useState('');
  const [maillogis, setmail_Logistica] = useState('');
  const [direccionTienda, setdireccionTienda] = useState('');
  const [telefonoTienda, settelefonoTienda] = useState('');
  const [contacto_tienda, setcontactoTienda] = useState('');
  const [id_tienda, setid_Tienda] = useState('');
  const [nombre_logistica, setnombreLogistica] = useState('');
  const [mail_logistica, setmailLogistica] = useState('');
  const [Name_Tienda, setNameTienda] = useState('');
  const [const_client_id, setConst_client_id] = useState('');
  const [const_client_secret, setConst_client_secret] = useState('');

  //const [emailData, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  function handleGo() {
    console.log('dentro del handle go()');
    navigate('/PortalAdmin');
  }
  function handleGoUsr() {
    console.log('dentro del handle goUsr()');
    navigate('/userDash');
  }

  function handleCliente() {
    console.log('dentro del handle genera()');
    navigate('/PortalCliente');
  }
  function handleTienda() {
    console.log('dentro del handle genera()');
    navigate('/PortalTienda');
  }
  function handleLogistic() {
    console.log('dentro del handle shipmaster()');
    navigate('/PortalLogistica');
  }
  function handleRider() {
    console.log('dentro del handle Rider()');
    navigate('/PortalRider');
  }

  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true); // Mostrar el loading

    // Envío de los datos al archivo PHP
    fetch('https://www.shipmaster.com.ar/backoffice/php/comandos.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usuario: usuario,
        contrasena: contrasena,
      }),
    })
      .then((response) => response.json()) // Convertir la respuesta a un objeto JSON
      .then((data) => {
        if (data.success) {
          setMensaje('¡Inicio de sesión exitoso!');
          // Mostrar los datos del usuario

          console.log('Datos del usuario:', data.usuario);
          console.log('Datos del rol:', data.usuario.rol);
          //guardo variables en el hook
          //setid_usuario(data.usuario.id_usuario);
          //localStorage.setItem("Id_usuario", id_usuario);
          //console.log("id usuario:", id_usuario)

          // Acceder a los valores de client_ml
          //const client_ml = data.usuario.client_ml;
          //console.log("Datos de client_ml:", client_ml);

          setConst_client_id(data.usuario.client_id);
          setConst_client_secret(data.usuario.client_secret);
          setUserId(data.usuario.id_usuario);
          setUserNombre(data.usuario.nombre);
          setUserMail(data.usuario.email);
          setRole(data.usuario.rol);
          setid_Logistica(data.usuario.id_logistica);
          setnombreLogistica(data.usuario.nombre_logistica);
          setmailLogistica(data.usuario.mail_logistica);
          setid_Tienda(data.usuario.id_tienda);
          setdireccionTienda(data.usuario.direccion_tienda);
          settelefonoTienda(data.usuario.telefono_tienda);
          setcontactoTienda(data.usuario.contacto_tienda);
          setNameTienda(data.usuario.nombre_tienda);

          localStorage.setItem('userId', data.usuario.id_usuario); //datauser
          localStorage.setItem('userNombre', data.usuario.nombre); //datauser
          localStorage.setItem('userMail', data.usuario.email); //datauser
          localStorage.setItem('id_logis', data.usuario.id_logistica); //id logistica
          localStorage.setItem('id_tienda', data.usuario.id_tienda); //id tienda
          localStorage.setItem('nombre_logistica', data.usuario.nombre_logistica); //id logistica
          localStorage.setItem('mail_logistica', data.usuario.mail_logistica); //id logistica
          localStorage.setItem('name_tienda', data.usuario.nombre_tienda); //id tienda
          localStorage.setItem('contacto_tienda', data.usuario.contacto_tienda); //id tienda
          localStorage.setItem('constclientid', data.usuario.client_id); //client_ml.client_id
          localStorage.setItem('direccion_tienda', data.usuario.direccion_tienda); //client_ml.client_id
          localStorage.setItem('telefono_tienda', data.usuario.telefono_tienda); //client_ml.client_id
          localStorage.setItem('constclientsecret', data.usuario.client_secret); //client_ml.client_secret

          //localStorage.setItem("macData", data.usuario.mac); //data mac user
          //console.log("mac: " + hwidLog);
          console.log('role: ' + roleData);
          //console.log("email: " + emailData);
          console.log('data.usuario.id : ' + data.usuario.id_usuario);
          console.log('userId : ' + userId);
          console.log('userNombre : ' + userNombre);
          console.log('userMail : ' + userMail);
          console.log('id_logis : ' + Idlogis);
          console.log('id_tienda : ' + id_tienda);
          console.log('name_logistica : ' + nombre_logistica);
          console.log('mail_logistica : ' + mail_logistica);
          console.log('name_tienda : ' + Name_Tienda);

          console.log('client_id:', const_client_id);
          console.log('client_secret:', const_client_secret);

          if (data.usuario.rol === '5') {
            // aca va el enlace para admin web
            console.log('aca va el enlace para admin web');
            handleGo();
          }
          if (data.usuario.rol === '3') {
            // aca va el enlace para usuario
            console.log('aca va el enlace para usuario');
            handleGoUsr();
          }
          if (data.usuario.rol === '3') {
            // aca va el enlace para local
            console.log('aca va el enlace para local web genera pedido del local');
            handleTienda();
          }
          if (data.usuario.rol === '4') {
            // aca va el enlace para cliente
            console.log('aca va el enlace para local web del cliente de la logistica');
            handleCliente();
          }
          if (data.usuario.rol === '1') {
            // aca va el enlace para logistica
            console.log('aca va el enlace para Portal Logistica');
            handleLogistic();
          }
          if (data.usuario.rol === '30') {
            // aca va el enlace para rider
            console.log('aca va el enlace para rider');
            handleRider();
          }
        } else {
          setMensaje(data.mensaje);
        }
        setLoading(false); // Ocultar el loading
      })
      .catch((error) => {
        console.log('Error de análisis JSON:', error);
      });
  };

  return (
    <div className='usuarios2ppal'>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <div className='login' style={{ alignItems: 'center' }}>
          <br />
          <div className='loginCard'>
            <h1 style={{ fontFamily: 'Arial Black', fontSize: '30px' }}>Login</h1>
            <br></br>
            <div className='logincampouser'>
              <form onSubmit={handleSubmit}>
                <input type='label' value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                <br />
                <input
                  style={{ marginTop: '5px' }}
                  type='password'
                  value={contrasena}
                  onChange={(e) => setContrasena(e.target.value)}
                />
                <br />
                <br />
                <button type='submit'>Iniciar sesión</button>
                {loading && (
                  <div className='loading-overlay'>
                    <div className='loading-container'>
                      <ReactLoading type='spinningBubbles' color='#0592F9' />
                    </div>
                  </div>
                )}{' '}
                <p>{mensaje}</p>
              </form>
              <br />
            </div>

            <p className='footerLogin'>Seguimiento en tiempo real.</p>
            {/* <p><strong>USUARIO:</strong> test - <strong>PASSWORD:</strong>test</p> */}

            <br />
            <div>
              <div onClick={toggleForm}>
                <p style={{ cursor: 'pointer' }}>Olvidaste tu password?</p>
              </div>
              {showForm && (
                <form>
                  <label htmlFor='email' style={{ marginLeft: '25px' }}>
                    Email:
                  </label>
                  <input type='email' id='email' name='email' style={{ marginRight: '2px' }} />
                  <button type='submit'>Enviar</button>
                </form>
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className='footerLog'>
        <p>XQR - Tecnologia y soluciones en e-logistica. </p>
      </div>
    </div>
  );
};
export default Usuarios2;
