import React, { useEffect, useState } from 'react';
import './mensajeria.css';
import Conversacion from './mensajeriaConversacion';

const Mensajeria = () => {
  const [idUsuario, setIdUsuario] = useState(localStorage.getItem('userId'));
  const [idTienda, setIdTienda] = useState(localStorage.getItem('id_tienda'));
  const [idLogistica, setIdLogistica] = useState(localStorage.getItem('id_logis'));
  const [receptores, setReceptores] = useState([]);
  const [conversacionSeleccionada, setConversacionSeleccionada] = useState(null);
  const [mensajesNoLeidos, setMensajesNoLeidos] = useState({}); // Objeto para almacenar los no leídos por receptor
  const [mostrarDetallesTienda, setMostrarDetallesTienda] = useState(false);

  useEffect(() => {
    obtenerReceptores(); // Cargar receptores disponibles
  }, []);

  const obtenerReceptores = async () => {
    let endpoint = '';
    if (idTienda === 0) {
      console.log('Entrando por 0')
      // Usuario es de logística, debe obtener tiendas asociadas a su id_logistica
      endpoint = `https://www.shipmaster.com.ar/backoffice/php/mensajeria/obtener_receptores_tiendas.php?id_logistica=${idLogistica}&id_tienda=${idTienda}`;
    } else {
      console.log('entrando por distinto de 0')
      console.log('logistica:', idLogistica, 'tienda', idTienda, 'usuario', idUsuario);
      // Usuario es de tienda, debe obtener usuarios de logística asociados a su id_logistica
      endpoint = `https://www.shipmaster.com.ar/backoffice/php/mensajeria/obtener_receptores_tienda_logistica.php?id_logistica=${idLogistica}&id_tienda=${idTienda}&id_usuario=${idUsuario}`;
    }

    try {
      const response = await fetch(endpoint);
      const data = await response.json();
      const receptoresFiltrados = data.filter((receptor) => receptor.id_usuario !== idUsuario);
      setReceptores(receptoresFiltrados); // Cargar los posibles receptores

      verificarMensajesNoLeidos(receptoresFiltrados);
    } catch (error) {
      console.error('Error al obtener los receptores:', error);
    }
  };

  const verificarMensajesNoLeidos = async (receptores) => {
    const nuevoEstadoMensajes = {};
    for (let receptor of receptores) {
      try {
        const response = await fetch(
          `https://www.shipmaster.com.ar/backoffice/php/mensajeria/verificar_mensajes_nuevos.php?id_receptor=${receptor.id_usuario}&id_emisor=${idUsuario}`,
        );
        const data = await response.json();
        nuevoEstadoMensajes[receptor.id_usuario] = data.tieneMensajesNuevos === 'true';
      } catch (error) {
        console.error('Error al verificar mensajes no leídos:', error);
      }
    }
    setMensajesNoLeidos(nuevoEstadoMensajes);
  };

  const seleccionarConversacion = (receptor) => {
    setConversacionSeleccionada(receptor); // Seleccionar receptor para iniciar conversación
    setMostrarDetallesTienda(false); // Ocultar los detalles de la tienda al seleccionar una nueva conversación
  };

  

  return (
    <div className='mensajeria-container'>
      <h4>Selecciona un receptor</h4>
      <ul>
        {receptores.length > 0 ? (
          receptores.map((receptor) => (
            <li key={receptor.id_usuario} onClick={() => seleccionarConversacion(receptor)} className='receptor-item'>
              <span className='receptor-nombre'>{receptor.usuario.toUpperCase()}</span> -{' '}
              {receptor.rol === '5'
                ? 'Admin'
                : receptor.id_tienda === '0'
                ? 'Logística'
                :  'Tienda'}
              {mensajesNoLeidos[receptor.id_usuario] && <span className='mensaje-nuevo'> - Mensaje nuevo</span>}
            </li>
          ))
        ) : (
          <li>No hay usuarios disponibles para comunicarte.</li>
        )}
      </ul>
      {conversacionSeleccionada && (
        <>
          <Conversacion
            idEmisor={idUsuario}
            idReceptor={conversacionSeleccionada.id_usuario}
            nombreReceptor={conversacionSeleccionada.usuario}
          />
          
        </>
      )}
    </div>
  );
};

export default Mensajeria;
