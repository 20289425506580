import React from 'react';
import './logo.css';

const Logo = ({ src, logoAppeared }) => {
  return (
      <div className='logocontainer'>
          
      <img src={src} width='100' alt='shipmaster' className={`box ${logoAppeared ? 'appear' : ''}`}/>
      <div className='pin'></div>
    </div>
  );
};

export default Logo;
 