// src/Soporte.js
import React, { useEffect, useState } from 'react';
import './soporte.css'; // Asegúrate de crear este archivo CSS

const Soporte = () => {
  const [tickets, setTickets] = useState([]);
  const [nameTicket, setNameTicket] = useState('');
  const [asuntoTicket, setAsuntoTicket] = useState('');
  const [mailTicket, setMailTicket] = useState('');
  const [newTicket, setNewTicket] = useState('');

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await fetch('http://shipmaster.com.ar/backoffice/php/admindash/ticketsListar.php');
      const data = await response.json(); // Asegúrate de manejar la respuesta como JSON
      setTickets(data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const handleCreateTicket = async () => {
    try {
      await fetch('http://shipmaster.com.ar/backoffice/php/admindash/ticketNuevo.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: nameTicket, asunto: asuntoTicket, mail: mailTicket, description: newTicket }),
      });
      setNameTicket('');
      setAsuntoTicket('');
      setMailTicket('');
      setNewTicket('');
      fetchTickets();
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  return (
    <div className='soporte-container'>
      <h1>Soporte Técnico</h1>
      <div className='form-container'>
        <h2>Crear nuevo ticket</h2>
        <div className='form-row'>
          <label>
            <span>Nombre</span>
            <input type='text' value={nameTicket} onChange={(e) => setNameTicket(e.target.value)} />
          </label>
          <label>
            <span>Asunto</span>
            <input type='text' value={asuntoTicket} onChange={(e) => setAsuntoTicket(e.target.value)} />
          </label>
          <label>
            <span>Mail</span>
            <input type='text' value={mailTicket} onChange={(e) => setMailTicket(e.target.value)} />
          </label>
        </div>
        <div className='form-row'>
          {' '}
          <label>
            <span>Mensaje</span>
            <textarea value={newTicket} onChange={(e) => setNewTicket(e.target.value)} />
          </label>
        </div>

        <button className='submit-button' onClick={handleCreateTicket}>
          Crear Ticket
        </button>
      </div>
      <h2>Tickets Abiertos</h2>
      <table className='tickets-table'>
        <thead>
          <tr>
            <th>Ticket #</th>
            <th>Descripción</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr key={ticket.id_ticket}>
              <td>{ticket.id_ticket}</td>
              <td>{ticket.descripcion}</td>
              <td>{ticket.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Soporte;
