import React, { useState, useEffect } from "react";

const AdministrarUsuarios = () => {
    const [userId, setUserId] = useState("");
    const [tiendas, setTiendas] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editTienda, setEditTienda] = useState(null); // Almacena los datos de la tienda seleccionada para editar
    const [usuariosTienda, setUsuariosTienda] = useState([]); // Almacena los usuarios de la tienda seleccionada
    const [showUsuariosForm, setShowUsuariosForm] = useState(false); // Mostrar el formulario de usuarios
    const [editUsuario, setEditUsuario] = useState(null); // Almacena los datos de la tienda seleccionada para editar
    const [editMode, setEditMode] = useState(false); // Modo de edición para los campos de usuario


    useEffect(() => {
        // Obtenemos el userId del localStorage
        const idlogist = localStorage.getItem("userId");
        console.log("ID del usuario:", idlogist);
        setUserId(idlogist);

        // Realizar la solicitud GET al servidor PHP con el userId como parámetro
        fetch(`https://shipmaster.com.ar//backoffice/php/admindash/listarusuarios.php`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de tiendas con los datos recibidos
                setTiendas(data);
            })
            .catch(error => console.error("Error al obtener las tiendas:", error));
    }, []);

    const openEditForm = (tienda) => {
        setEditTienda(tienda);
        setShowEditForm(true);
    };

    const closeEditForm = () => {
        setShowEditForm(false);
        setEditTienda(null);
    };

    const closeUsuariosForm = () => {
        setShowUsuariosForm(false);
        setEditUsuario(null);
    };

    const handleSave = () => {
        console.log("Datos de editTienda:", editTienda); // Imprime los datos de editTienda por consola

        // Realiza una solicitud POST al servidor PHP para actualizar la tienda
        fetch('https://shipmaster.com.ar/backoffice/php/listartiendasactualizar.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editTienda), // Envía los datos de editTienda al servidor
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cierra el formulario de edición
                    closeEditForm();
                    // Actualiza la lista de tiendas haciendo una nueva solicitud GET
                    fetchTiendas(); // Llama a la función fetchTiendas para obtener las tiendas actualizadas
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al actualizar la tienda');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };


    const handleCancel = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        closeEditForm();
    };

    const handleCancelUsers = () => {
        // Simplemente cierra el formulario de edición sin hacer ningún cambio
        //setEditMode({ ...editMode, [index]: false });
        if (editMode === true) { setEditMode({ ...editMode, [0]: false }); }
        closeUsuariosForm();
    };




    const handleDelete = (idTienda) => {
        // Mostrar una ventana de confirmación antes de eliminar el registro
        if (window.confirm("¿Estás seguro de que deseas eliminar esta tienda?")) {
            // Realiza una solicitud POST al servidor PHP para eliminar la tienda
            fetch('https://shipmaster.com.ar/backoffice/php/listarusuarios.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_tienda: idTienda }), // Envía el ID de la tienda a eliminar
            })
                .then(response => {
                    if (response.ok) {
                        // Si la solicitud fue exitosa, actualiza la lista de tiendas
                        fetchTiendas(); // Llama a la función fetchTiendas para obtener las tiendas actualizadas
                    } else {
                        // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                        console.error('Error al eliminar la tienda');
                    }
                })
                .catch(error => {
                    console.error('Error al enviar la solicitud:', error);
                });
        }
    };

    const handleEdit = (index) => {
        // Cambiar al modo de edición para el usuario en la posición de índice
        setEditMode({ ...editMode, [index]: true });
    };

    const handleCancelEdit = (index) => {
        // Cancelar la edición y volver al modo de visualización para el usuario en la posición de índice
        setEditMode({ ...editMode, [index]: false });
    };

    const fetchUsuariosTienda = (idTienda) => {
        // Realizar una solicitud GET al servidor para obtener los usuarios de la tienda
        fetch(`https://shipmaster.com.ar/backoffice/php/listartiendasusuarios.php?idTienda=${idTienda}`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de usuariosTienda con los datos recibidos
                setUsuariosTienda(data);
                // Mostrar el formulario de usuarios de la tienda
                setShowUsuariosForm(true);
            })
            .catch(error => console.error("Error al obtener los usuarios de la tienda:", error));
    };

    const handleInputChange = (e, index) => {
        // Actualizar el usuario en la posición de índice con el nuevo valor
        const updatedUsuarios = [...usuariosTienda];
        updatedUsuarios[index].usuario = e.target.value;
        setUsuariosTienda(updatedUsuarios);
    };

    const handleSaveEdit = (index) => {
        // Realiza una solicitud POST al servidor PHP para actualizar el usuario
        fetch('https://shipmaster.com.ar/backoffice/php/listartiendaseditarusuario.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(usuariosTienda[index]), // Envía los datos del usuario editado al servidor
        })
            .then(response => {
                if (response.ok) {
                    // Si la solicitud fue exitosa, cancela el modo de edición
                    setEditMode({ ...editMode, [index]: false });
                    // Actualiza la lista de usuarios haciendo una nueva solicitud GET
                    fetchUsuariosTienda(editTienda.id_tienda); // Llama a la función fetchUsuariosTienda para obtener los usuarios actualizados
                } else {
                    // Si la solicitud falla, muestra un mensaje de error o maneja la situación de otra manera
                    console.error('Error al actualizar el usuario');
                }
            })
            .catch(error => {
                console.error('Error al enviar la solicitud:', error);
            });
    };


    const fetchTiendas = () => {
        // Realizar una solicitud GET al servidor para obtener las tiendas actualizadas
        fetch(`https://shipmaster.com.ar/backoffice/php/admindash/listarusuarios.php?userId=${userId}`)
            .then(response => response.json())
            .then(data => {
                // Actualizar el estado de tiendas con los datos recibidos
                setTiendas(data);
            })
            .catch(error => console.error("Error al obtener las tiendas:", error));
    };

    return (
        <div className="containercursos">
            <h4>
                Administrar Usuarios{" "}
                <i
                    className="fas fa-graduation-cap"
                    style={{ paddingLeft: "20px", color: "#0c5da1" }}
                ></i>
            </h4>
            <h4>{userId}</h4>

            {/* Mostrar las tiendas en una tabla */}
            <table>
                <thead>
                    <tr>
                        {/** `usuarios`(`id_usuario`, `usuario`, `pass`, `jerarquia`, `rol`, `mail_usuario`, `id_logistica`, `id_tienda`, `fecha`, `activo`)  */}
                        <th>id_usuario</th>
                        <th>usuario</th>
                        <th>pass</th>
                        <th>jerarquia</th>
                        <th>rol</th>
                        <th>mail_usuario</th>
                        <th>id_logistica</th>
                        <th>id_tienda</th>
                        <th>fecha</th>
                        <th>activo</th>
                        <th>O   pciones</th>
                    </tr>
                </thead>
                <tbody>
                    {tiendas.map(tienda => (
                        <tr key={tienda.id_usuario}>
                            <td>{tienda.id_usuario}</td>
                            <td>{tienda.usuario}</td>
                            <td>{tienda.pass}</td>
                            <td>{tienda.jerarquia}</td>
                            <td>{tienda.rol}</td>
                            <td>{tienda.mail_usuario}</td>
                            <td>{tienda.id_logistica}</td>
                            <td>{tienda.id_tienda}</td>
                            <td>{tienda.fecha}</td>
                            <td>{tienda.activo}</td>
                            <td>
                                <button onClick={() => fetchUsuariosTienda(tienda.id_usuario)}>no usar</button>
                                <button onClick={() => openEditForm(tienda)}>Editar</button>
                                <button onClick={() => handleDelete(tienda.id_usuario)}>Eliminar</button> {/* boton eliminar */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Mostrar el formulario de edición si showEditForm es true */}
            {showEditForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Editar Usuarios</h3>
                        <form style={{ marginLeft: '50px', marginRight: '50px', marginBottom: '20px' }}>
                            <label htmlFor="nombre">Nombre:</label>
                            <input type="text" id="nombre" name="nombre" value={editTienda.nombre_tienda} onChange={(e) => setEditTienda({ ...editTienda, nombre_tienda: e.target.value })} />
                            <label htmlFor="direccion">Dirección:</label>
                            <input type="text" id="direccion" name="direccion" value={editTienda.direccion_tienda} onChange={(e) => setEditTienda({ ...editTienda, direccion_tienda: e.target.value })} />
                            <label htmlFor="mail">Mail:</label>
                            <input type="text" id="mail" name="mail" value={editTienda.mail_tienda} onChange={(e) => setEditTienda({ ...editTienda, mail_tienda: e.target.value })} />

                            <label htmlFor="telefono">Telefono:</label>
                            <input type="text" id="telefono" name="telefono" value={editTienda.telefono_tienda} onChange={(e) => setEditTienda({ ...editTienda, telefono_tienda: e.target.value })} />

                            <label htmlFor="cuit">CUIT:</label>
                            <input type="text" id="cuit" name="cuit" value={editTienda.cuit_tienda} onChange={(e) => setEditTienda({ ...editTienda, cuit_tienda: e.target.value })} />

                            <label htmlFor="contacto">Contacto:</label>
                            <input type="text" id="contacto" name="contacto" value={editTienda.contacto_tienda} onChange={(e) => setEditTienda({ ...editTienda, contacto_tienda: e.target.value })} />


                            {/* Agrega más campos según necesites */}
                        </form>
                        <button onClick={handleSave}>Guardar</button>
                        <button onClick={handleCancel}>Cancelar</button>
                        <p style={{ marginBottom: '30px' }} />
                    </div>
                </div>
            )}


            {/* Mostrar el formulario de usuarios de la tienda si showUsuariosForm es true */}
            {/* Mostrar el formulario de usuarios de la tienda si showUsuariosForm es true */}
            {showUsuariosForm && (
                <div className="modal-container">
                    <div className="modal-content">
                        <h3>Usuarios de la Tienda</h3>
                        {/* Tabla para mostrar los usuarios de la tienda */}
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Usuario</th>
                                    <th>Password</th>
                                    <th>Mail</th>
                                    <th>Jerarquía</th>
                                    <th>Rol</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usuariosTienda.map((usuario, index) => (
                                    <tr key={usuario.id_usuario}>
                                        <td>{usuario.id_usuario}</td>
                                        <td>
                                            {/* Si está en modo de edición, muestra un campo de entrada, de lo contrario, solo muestra el valor */}
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.usuario}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.usuario
                                            )}
                                        </td>
                                        <td>
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.pass}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.pass
                                            )}
                                        </td>
                                        <td>
                                            {editMode[index] ? (
                                                <input
                                                    type="text"
                                                    value={usuario.mail_usuario}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            ) : (
                                                usuario.mail_usuario
                                            )}
                                        </td>

                                        <td>{usuario.jerarquia}</td>
                                        <td>{usuario.rol}</td>
                                        <td>
                                            {/* Mostrar el botón "Guardar" si está en modo de edición, de lo contrario, muestra "Editar" */}
                                            {editMode[index] ? (
                                                <button onClick={() => handleSaveEdit(index)}>Guardar</button>
                                            ) : (
                                                <button onClick={() => handleEdit(index)}>Editar</button>
                                            )}
                                            {/* Mostrar el botón "Cancelar" si está en modo de edición, de lo contrario, muestra "Eliminar" */}
                                            {editMode[index] && (
                                                <button onClick={() => handleCancelEdit(index)}>Cancelar</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        <br />
                        <button onClick={handleCancelUsers} style={{ marginBottom: '20px' }}>Cancelar</button>
                        <br />
                    </div>
                    <br />
                </div>
            )}
            <div className="footer">
                <p>shipmaster - Tecnología en shipmaster</p>
            </div>
        </div>
    );
};

export default AdministrarUsuarios;
