import React, { useEffect, useState } from "react";
import JsonData from "../../data/data.json";
import Tienda from "../dashboards/tienda";

import "./usuarios.css";

const PortalTienda = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [userId, setUserId] = useState(''); // id de usuario
  const [userNombre, setUserNombre] = useState(''); // nombre usuario
  const [id_logis, setidLogistica] = useState(''); // nombre logistica 
  const [nameLogistica, setUserLogistica] = useState(''); // nombre logistica
  const [nameTienda, setUserNombreTienda] = useState('');
  const [direccionTienda, setdireccionTienda] = useState('');
  const [id_tienda, setidTienda] = useState('');
  const [telefonoTienda, settelefonoTienda] = useState('');
  //const [userMail, setUserMail] = useState("");  // mail de usuario
 
  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    setUserNombre(localStorage.getItem('userNombre'));
    setidLogistica(localStorage.getItem('id_logis'));
    setUserLogistica(localStorage.getItem('nombre_logistica'));
    setUserNombreTienda(localStorage.getItem('name_tienda'));
    setdireccionTienda(localStorage.getItem('direccion_tienda'));
    settelefonoTienda(localStorage.getItem('telefono_tienda'));
    setidTienda(localStorage.getItem('id_tienda'));
    //setUserMail(localStorage.getItem("userMail"));
    console.log('userId:' + userId);
    setLandingPageData(JsonData);
  }, [userId, userNombre]);

  return (
    <div className='fondodash' style={{ overflowY: 'none' }}>
      <div className='centrado'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='centro'>
          <p>
            <h3 style={{ display: 'inline' }}>TIENDA:</h3>
            <h2 style={{ display: 'inline' }}>
              {' '}
              {nameTienda} nro:{id_tienda}
            </h2>
          </p>
          <p>
            <h5 style={{ display: 'inline' }}>Logistica distribucion:{id_logis}-{nameLogistica} -</h5>
            <h5 style={{ display: 'inline' }}>
              {' '}
              Usuario: {userNombre}({userId})
            </h5>
          </p>
          <Tienda data={landingPageData.Tienda} />
        </div>
      </div>
    </div>
  );
};
export default PortalTienda;
