import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './tiendadashboard.css';

const TiendaDashBoard = () => {
  const [data, setData] = useState({
    ventasRealizadas: 0,
    enviosColectados: 0,
    pedidosEnLogistica: 0,
    pedidosEnRuta: 0,
    pedidosReprogramados: 0,
    pedidosCancelados: 0,
    pedidosEntregados: 0,
  });
  const [id_logis, setidLogistica] = useState(''); // nombre logistica
  const [userId, setUserId] = useState(''); // id de usuario
  const [userNombre, setUserNombre] = useState(''); // nombre usuario
  const [id_tienda, setidTienda] = useState('');
  const [isLoading, setIsLoading] = useState(true); // estado para iconode carga
  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    setUserNombre(localStorage.getItem('userNombre'));
    setidLogistica(localStorage.getItem('id_logis'));
    setidTienda(localStorage.getItem('id_tienda'));
  }, [userId, userNombre]);

  useEffect(() => {
    setIsLoading(true); // Empezar la carga
    const fetchData = async () => {
      try {
        const response = await fetch('https://shipmaster.com.ar/backoffice/php/admindash/dashboardtienda.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({ id_tienda: id_tienda }),
        });
        const result = await response.json();
        setData(result);
        setIsLoading(false); // Terminar la carga
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Terminar la carga
      }
    };

    fetchData();
  }, [id_tienda]);

  // Simulación de datos; en una aplicación real, estos datos vendrían de una API o base de datos.
  const {
    ventasRealizadas,
    enviosColectados,
    pedidosEnLogistica,
    pedidosEnRuta,
    pedidosReprogramados,
    pedidosCancelados,
    pedidosEntregados,
  } = data;

  useEffect(() => {
    setUserId(localStorage.getItem('userId'));
    setUserNombre(localStorage.getItem('userNombre'));
    setidLogistica(localStorage.getItem('id_logis'));
  }, [userId, userNombre]);

  return (
    <div>
      <div className='centro' style={{ alignContent: 'center' }}>
        <div>
          <h4 className='centro'>
            TIENDA Tienda Dash Board {id_tienda}
            <i className='fas fa-chart-bar icono' style={{ color: '#b87f05' }}></i>
          </h4>
        </div>
      </div>
      <div className='dashboard-container'>
        {isLoading ? ( // Mostrar el loading mientras se cargan los datos
          <div className='loading-icon'>
            <i className='fas fa-spinner fa-spin' style={{ fontSize: '50px', color: '#b87f05' }}></i>
            <p>Cargando datos...</p>
          </div>
        ) : (
          <>
            <div className='dashboard-card'>
              <h3>Ventas Realizadas</h3>
              <p>{ventasRealizadas}</p>
            </div>
            <div className='dashboard-card'>
              <h3>Envíos Colectados</h3>
              <p>{enviosColectados}</p>
            </div>
            <div className='dashboard-card'>
              <h3>Pedidos en Logística</h3>
              <p>{pedidosEnLogistica}</p>
            </div>
            <div className='dashboard-card'>
              <h3>Pedidos en Ruta</h3>
              <p>{pedidosEnRuta}</p>
            </div>
            <div className='dashboard-card'>
              <h3>Pedidos Reprogramados</h3>
              <p>{pedidosReprogramados}</p>
            </div>
            <div className='dashboard-card'>
              <h3>Pedidos Cancelados</h3>
              <p>{pedidosCancelados}</p>
            </div>{' '}
            <div className='dashboard-card'>
              <h3>Pedidos Entregados</h3>
              <p>{pedidosEntregados}</p>
            </div>{' '}
          </>
        )}
      </div>
      <br />
      <div className='footVerde'>
        <p>XQR - Experiencia y Tecnología para logísticas</p>
      </div>
    </div>
  );
};

export default TiendaDashBoard;
