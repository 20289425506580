import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";

const Escanear = () => {
  const [userId, setUserId] = useState("");
  const [userNombre, setUserNombre] = useState(""); // nombre usuario
  const [userMail, setUserMail] = useState(""); // mail de usuario

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setUserNombre(localStorage.getItem("userNombre"));
    setUserMail(localStorage.getItem("userMail"));
  }, []);

  const handleScan = (data) => {
    if (data) {
      alert("Código QR escaneado: " + data);
      // Aquí puedes manejar la lógica cuando se escanea un código
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleResult = (result) => {
    // Puedes manejar el resultado aquí si es necesario
    console.log("Resultado del escaneo:", result);
  };

  return (
    <div className="contenedorInicio">
      <div className="Dashtituloinicio">
        <div className="divInicio">
          <div>
            <h2>Escanear Etiqueta Envío - usuario:{userId} </h2>
            <h4>
              {userNombre}--{userMail}
            </h4>
            <QrReader
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              onResult={handleResult} 
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="footVerde">
          <p>Logistic - Tecnologia en shipmaster </p>
        </div>
      </div>
    </div>
  );
};

export default Escanear;
