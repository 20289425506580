import React, { useEffect, useState } from 'react';

const CrudPost = () => {
  const [datos, setDatos] = useState({});
  const [mensaje, setMensaje] = useState('');

  useEffect(() => {
    
    fetch('https://localhost:3000/blogdata/posteos')
      .then(res => res.json())
      .then(data => setDatos(data))
      .catch(error => console.error(error));
  }, []);

  const handleChange = event => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const options = {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    fetch('http://localhost:3000/blogdata/posteos', options)
      .then(res => res.json())
      .then(data => {
        setDatos({});
        setMensaje('Datos guardados con éxito');
      })
      .catch(error => {
        setMensaje('Error al guardar los datos');
        console.error(error);
      });
  };

  return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="nombre"
        value={datos.nombre || ''}
        onChange={handleChange}
      />
      <input
        type="email"
        name="email"
        value={datos.email || ''}
        onChange={handleChange}
      />
      <button type="submit">Guardar</button>
      {mensaje && <p>{mensaje}</p>}
    </form>
    </div>
  );
};

export default CrudPost;
