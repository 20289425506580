import React from "react";
import { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
//import { HeadCarrousel } from "../components/headcarrousel";
import { Header } from "../components/header";
//import { Descripcion } from "../components/descripcion";
import { About } from "../components/about";
//import { Codigo } from "../components/codigo";
//import { Gallery } from "../components/gallery";
//import { Testimonials } from "../components/testimonials";
import { Team } from "../components/Team";
import { Contact } from "../components/contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll"; 
import "../App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      {/* <HeadCarrousel />  */}
      <About data={landingPageData.About} />
      {/*<Descripcion data={landingPageData.Descripcion} />
      <Codigo data={landingPageData.Codigo} />
      <Gallery data={landingPageData.Gallery} />*/}
      {/*<Testimonials data={landingPageData.Testimonials} /> */}
      <Team data={landingPageData.Team} />  
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
