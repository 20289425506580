import React, { useEffect, useState } from 'react';
import './mensajeria.css';

const Conversacion = ({ idEmisor, idReceptor, nombreReceptor }) => {
  const [mensaje, setMensaje] = useState('');
  const [mensajes, setMensajes] = useState([]);

  useEffect(() => {
    obtenerMensajes(); // Cargar historial de mensajes
  }, [idReceptor]);

  const enviarMensaje = async () => {
    const formData = new FormData();
    formData.append('id_emisor', idEmisor);
    formData.append('id_receptor', idReceptor);
    formData.append('mensaje', mensaje);

    try {
      const response = await fetch('https://www.shipmaster.com.ar/backoffice/php/mensajeria/enviar_mensaje.php', {
        method: 'POST',
        body: formData,
      });
      setMensaje(''); // Limpiar mensaje
      obtenerMensajes(); // Refrescar historial
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
    }
  };

  const obtenerMensajes = async () => {
    try {
      const response = await fetch(
        `https://www.shipmaster.com.ar/backoffice/php/mensajeria/obtener_mensajes.php?id_emisor=${idEmisor}&id_receptor=${idReceptor}`,
      );
      const data = await response.json();
      setMensajes(data); // Historial de mensajes
    } catch (error) {
      console.error('Error al obtener los mensajes:', error);
    }
  };

  return (
    <div className='conversacion-container'>
      <h4>
        Conversación con<span className='receptor-nombre'>{nombreReceptor.toUpperCase()}</span>{' '}
      </h4>
      <div className='mensajes'>
        <ul>
          {mensajes.length > 0 ? (
            mensajes.map((msg) => (
              <li key={msg.id} style={{ background: msg.leido ? '#e0e0e0' : '#f5f5f5' }}>
                <strong>{msg.id_emisor === idEmisor ? 'Tú: ' : 'Receptor: '}</strong>
                {msg.mensaje}
                <span style={{ float: 'right', fontSize: '12px' }}>{new Date(msg.fecha).toLocaleString()}</span>
              </li>
            ))
          ) : (
            <li>No hay mensajes en esta conversación.</li>
          )}
        </ul>
      </div>
      <textarea
        value={mensaje}
        onChange={(e) => setMensaje(e.target.value)}
        placeholder='Escribe tu mensaje'
      ></textarea>
      <button onClick={enviarMensaje}>Enviar</button>
    </div>
  );
};

export default Conversacion;
